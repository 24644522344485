<template>
  <div>
    <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
    <div v-if="getLoadingFromAuthentication" class="text-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <!-- page start-->
    <section v-else class="card">
      <header class="card-header">
        All orders
        <span class="pull-right">
          <!-- <a href="#" class=" btn btn-success btn-sm"> Create New Project</a> -->
          <router-link
            :to="{ name: 'newuser' }"
            class="btn btn-success btn-sm"
            >{{ $t("links.newuser") }}</router-link
          >
        </span>
      </header>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <input
              type="text"
              @keyup="searchTable"
              v-model="searchString"
              placeholder="Search Here"
              class="input-sm form-control"
            />
          </div>
        </div>
      </div>
      <table class="table table-hover p-table" id="orderTable">
        <thead>
          <tr>
            <th>Username</th>
            <th class="">Mail</th>
            <th>First name</th>
            <th>Last name</th>
            <th>Company</th>
            <th>Is superuser</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in getUsers" :key="user.id">
            <td class="p-name">
              {{ user.username }}
            </td>
            <td class="">
              {{ user.email }}
            </td>
            <td class="">
              {{ user.firstName }}
            </td>
            <td class="">
              {{ user.lastName }}
            </td>
            <td class="">
              {{ user.company }}
            </td>
            <td>
              {{ user.isSuperUser }}
            </td>
            <td>
              <!-- <b-button>reset password</b-button> -->
              <!-- <router-link  class="btn btn-primary btn-sm mr-2" :to="'/Admin/user/' + user.id"><i class="fas fa-folder"></i> {{ $t("items.view") }}</router-link>  -->
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <!-- page end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "users",
  methods: {
    searchTable() {
      // Declare variables
      var filter, table, tr, td, i, txtValue;

      filter = this.searchString.toUpperCase();
      table = document.getElementById("orderTable");
      tr = table.getElementsByTagName("tr");

      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[0];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    },
    ...mapActions(["loadUsers"])
  },
  computed: mapGetters(["getUsers", "getLoadingFromAuthentication"]),
  data() {
    return {
      searchString: "",
      users: [],
      error: null
    };
  },
  created() {
    this.loadUsers()
      .then(() => {
        this.users = this.getUsers;
      })
      .catch(erro => {
        this.error = erro;
      });
  }
};
</script>

<style></style>
